@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

:root {
  --mushroom: #e1c8af;
  --mazy: #c2915f;
  --bali: #8299ac;
  --fadedSea: #8d99ae;
  --black: #000;
  --marine: #053358;
  --white: #fff;
  --lightBlue: #3470B7;
  --blueShadow: rgba(52, 112, 183, 0.4);
  --toastify-color-progress-light: linear-gradient(to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55);
  --toastify-color-light: #fca311;
}

::-webkit-scrollbar {
  display: none;
}

* {
  margin: 0;
  padding: 0;
}

.wrapper {
  overflow-x: hidden;
  overflow-y: auto;
}

.error-msg {
  color: red;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .mybtn {
    width: 100%;
  }
}

html,
body {
  width: 100%;
  height: 100vh;
  font-family: "Open Sans", serif;
}

.navbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
}

/* .navbox .logo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
} */

/* .navbox .logo img {
  width: 500px;
}

@media screen and (max-width: 768px) {
  .navbox .logo img {
    width: 200px;
  }
} */

.navbox .rightside {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.login {
  cursor: pointer;
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 100px;
  color: var(--black);
  transition: all;
  transition-duration: 0.2s;
}

.login:hover {
  text-decoration: underline;
  color: var(--fadedSea);
  text-underline-offset: 5px;
  transition: all;
  transition-duration: 0.2s;
}

.google-login-btn {
  background-color: var(--black);
  color: var(--white);
  padding: 15px 40px;
  border-radius: 100px;
  font-size: 13px;
  font-weight: 300;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.google-login-img {
  width: 18px;
  margin-right: 10px;
}

.google-login-btn:hover {
  filter: brightness(0.7);
}

.sign-up {
  cursor: pointer;
  text-decoration: none;
  padding: 14px 50px;
  border-radius: 100px;
  color: var(--white);
  background-color: var(--lightBlue);
  font-size: 14px;
  font-weight: 600;
  outline: none;
  border: 1px solid var(--lightBlue);
  transition: all .3s ease-in-out;
}

.sign-up:hover {
  color: var(--lightBlue);
  background: transparent;
}

.pay-btn {
  cursor: pointer;
  width: 50%;
  text-decoration: none;
  padding: 12px 16px;
  border-radius: 100px;
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  outline: none;
  border: 1px solid var(--black);
  background-color: var(--black);
  box-shadow: 0px 0px 2px var(--lightBlue);
  transition: all;
  transition-duration: 0.2s;
}

.pay-btn:hover {
  text-decoration: none;
  background-color: transparent;
  box-shadow: 2px 4px var(--lightBlue);
  color: var(--lightBlue);
  border: 1px solid var(--lightBlue);
  transition: all;
  transition-duration: 0.2s;
}

.navlink {
  text-decoration: none !important;
  transition: all;
  transition-duration: 0.2s;
}

.navlink:hover {
  text-decoration: none !important;
  text-underline-offset: 5px;
  transition: all;
  transition-duration: 0.2s;
}

.navbox .rightside .no-of-products {
  background: var(--lightBlue);
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  /* position: absolute;
  top: 28px;
  right: 140px;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.logout-btn {
  padding: 8px 20px;
  border-radius: 100px;
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  border: 1px solid var(--black);
  background-color: var(--black);
  margin-left: 10px;
  box-shadow: 0px 0px 2px var(--lightBlue);
  transition: all;
  transition-duration: 0.2s;
}

.logout-btn:hover {
  background-color: transparent;
  box-shadow: 2px 4px var(--lightBlue);
  color: var(--lightBlue);
  border: 1px solid var(--lightBlue);
  transition: all;
  transition-duration: 0.2s;
}

.navbar-mobile-menu {
  display: none;
  z-index: 100;
  position: absolute;
  top: 40px;
  right: 30px;
}

.mobile-nav {
  display: none;
}

.filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
  width: 100%;
  padding: 20px;
  color: var(--black);
}

/* Style for the filter dropdown */
.filters select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 100px;
  font-size: 16px;
}


/* Style for the "See More" button */
.see-more-btn {
  cursor: pointer;
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 100px;
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  outline: none;
  border: 1px solid var(--black);
  background-color: var(--black);
  margin-left: 10px;
  box-shadow: 0px 0px 2px var(--lightBlue);
  transition: all;
  transition-duration: 0.2s;
  margin-top: 20px;
}

.see-more-btn:hover {
  text-decoration: none;
  background-color: transparent;
  box-shadow: 2px 4px var(--lightBlue);
  color: var(--lightBlue);
  border: 1px solid var(--lightBlue);
  transition: all;
  transition-duration: 0.2s;
}

@media (max-width: 768px) {
  .navbox {
    flex-direction: column;
  }

  .navbox .rightside {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }

  .navbox .rightside .logout-btn {
    margin-left: 0px;
  }

  .navbar-mobile-menu {
    color: var(--black);
    display: block;
  }

  .mobile-nav {
    display: flex;
  }

}

.shop-now {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 40px;
  font-size: 14px;
  font-weight: 600;
  color: var(--white);
  background-color: var(--lightBlue);
  border: 0.5px solid var(--lightBlue);
  border-radius: 100px;
  transition: all .3s ease-in-out;
}

.shop-now:hover {
  box-shadow: 4px 4px var(--blueShadow);
}

.link-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.main-container {
  width: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url("./images/main-image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.landing__container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap-reverse;
  background-color: transparent;
  /* background-color: var(--black); */
  /* background-image: url("./images/main-image-1.jpg");
  filter: brightness(0.5); */
  /* background-image: url("./images/bg.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.landing__header__container {
  width: 100%;
  min-height: 450px;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
}

.landing__header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}

.landing__header__main {
  font-size: 4.6rem;
  font-weight: 700;
  margin-left: 0;
  padding: 0;
  border-bottom: none;
  line-height: 110%;
}

.landing__header__discount {
  font-size: 1.1rem;
  color: var(--lightBlue);
}

.landing__image__container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing__image {
  height: auto;
  width: auto;
  top: 0%;
  z-index: 10;
}

@media screen and (max-width: 480px) {
  .filters {
    flex-direction: column;
    width: 100%;
    align-items: start;
  }

  .filters select {
    width: 100%;
  }

  .landing__container {
    background-position: -550px;
  }
}

@media screen and (max-width: 850px) {
  .landing__image__container {
    display: none;
  }

  .landing__header__main {
    font-size: 2.8rem;
    text-align: start;

  }

  .landing__header__discount {
    font-size: 0.8rem;
  }

  .landing__header {
    gap: 10px;
  }

  .shop-now {
    padding: 10px 20px;
    font-size: 12px;
  }
}

.about-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 100px 0px;
  flex-direction: row-reverse;
}

.about-content {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.about-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-left: 0;
  padding: 0;
  border-bottom: none;
  line-height: 110%;
  background-color: var(--lightBlue);
  color: var(--black);
  height: 30px;
}

.about-content p {
  font-size: 1.1rem;
  color: var(--fadedSea);
  line-height: 1.5;
  margin-left: 0;
  padding: 0;
  border-bottom: none;
  max-width: 90%;
}

.about-image {
  width: 100%;
  height: auto;
  z-index: 10;
}

.about-image img {
  width: 400px;
  z-index: 10;
}

@media (max-width: 768px) {
  .about-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
  }

  .about-content {
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    max-width: 90%;
    gap: 5px;
  }

  .about-content h1 {
    font-size: 2rem;
    height: auto;
    text-align: left;
  }

  .about-content p {
    text-align: justify;
    font-size: 0.8rem;
    max-width: 100%;
  }

  .about-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-image img {
    width: 300px;
  }
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: var(--black);
}

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--black);
}

.login-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: var(--black);
}

.login-titles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.login-heading {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: 700;
  margin-left: 0;
  padding: 0;
  border-bottom: none;
  line-height: 110%;
  background-color: var(--white);
  height: 30px;
}

.login-sub-heading {
  font-size: 1.1rem;
  font-weight: 300;
  color: var(--black);
  line-height: 1.5;
  margin-left: 0;
  padding: 0;
  border-bottom: none;
}

.login-form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.email-input {
  border: 1px solid var(--black);
  border-radius: 10px;
  width: 100%;
  transition: all;
  transition-duration: 0.2s;
}

.password-input {
  border: 1px solid var(--black);
  border-radius: 10px;
  width: 100%;
  transition: all;
  transition-duration: 0.2s;
}

.login-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  z-index: 10;
}

.login-image img {
  width: 600px;
  z-index: 10;
}

.login-fields {
  background-image: url("./images/login-bg.jpg");
  /* background-color: var(--white); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  color: var(--black);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

input:focus {
  color: var(--white);
  outline: none;
  border-bottom: 2px solid var(--black);
  transition: all;
  transition-duration: 0.2s;
  background-color: transparent !important;
}

input::placeholder {
  opacity: 50%;
  font-weight: 300;
  font-size: 14px;
  background-color: transparent !important;
}

input {
  color: var(--black);
  background-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--lightBlue) !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px var(--black) inset !important;
}

.login-btn {
  padding: 14px 15px;
  border-radius: 100px;
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  border: 1px solid var(--lightBlue);
  background-color: var(--lightBlue);
  width: 100%;
  margin: auto;
  transition: all .3s ease-in-out;
}

.login-btn:hover {
  filter: brightness(1.2);
}

.register-here-title {
  color: var(--black);
  font-weight: 300;
  text-align: center;
  font-size: 14px;
}

.register-here {
  color: var(--black);
  text-decoration: none;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
  transition: all;
  transition-duration: 0.2s;
}

.register-here:hover {
  color: var(--lightBlue);
  cursor: pointer;
  text-decoration: none;
  transition: all;
  transition-duration: 0.2s;
}

@media screen and (max-width: 850px) {
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100vh;
  }

  .login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    gap: 20px;
    width: 100%;
    height: 100%;
  }

  .login-fields {
    gap: 15px;
    width: 100%;
  }

  .login-form {
    width: 100%;
  }

  .login-btn {
    width: 100%;
  }

  .login-titles {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1px;
  }

  .login-sub-heading {
    font-size: 0.9rem;
  }

  .login-heading {
    font-size: 2rem;
    background-color: transparent !important;
  }

  .login-wrapper {
    height: 100%;
  }

  .login-container {
    height: 100vh;
  }

  .login-image {
    height: 100%;
  }

  .login-image img {
    width: 100%;
  }

  .register-here-title {
    font-size: 0.7rem;
  }
}

/* Error 404 */
.error-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.error-headline {
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
  width: 100%;
  color: var(--black);
}

@media screen and (max-width: 850px) {
  .error-headline {
    font-size: 1rem !important;
    text-align: center;
  }
}

/* Loader */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 20px;
}

/* products */

.products-main-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.products-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.products-wrapper h1 {
  text-align: left;
  padding: 30px;
  font-weight: 700;
  width: 100%;
}

.products-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 20px;
}

.products-container .product-card {
  width: 300px;
  height: auto;
  margin: 20px;
  border: 1px solid var(--lightBlue);
  border-radius: 20px;
  padding: 12px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.product-card:hover {
  box-shadow: 10px 10px var(--blueShadow);
}

.products-container .product-card .product-img {
  width: 100%;
  height: 200px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.products-container .product-card .product-img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.products-container .product-card .product-name {
  width: 100%;
  color: var(--black);
}

.products-container .product-card .product-price {
  width: 100%;
}

.products-container .product-card .addcart-btn {
  color: var(--white);
  background-color: var(--lightBlue);
  cursor: pointer;
  width: 100%;
  padding: 10px 14px;
  outline: none;
  border-radius: 50px;
  /* border: 1px solid var(--lightBlue); */
  transition: all 0.3s ease-in-out;
}

.products-container .product-card .addcart-btn:hover {
  box-shadow: 4px 4px var(--blueShadow);
  /* background-color: var(--blueShadow); */
  /* color: var(--black); */
  /* border-color: var(--blueShadow); */
}

/* cart */
.cart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}

.cart-container .cart-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .cart-container .cart-card {
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #e4e4e4;
  }
}

.cart-container .cart-card .cart-img {
  width: 150px;
  height: 120px;
}

@media (max-width: 768px) {
  .cart-container .cart-card .cart-img {
    width: 90%;
    height: auto;
  }
}

.cart-container .cart-card .cart-img img {
  width: 100%;
  height: 100%;
}

.cart-container .cart-card .cart-name,
.cart-container .cart-card .cart-price {
  width: 150px;
  margin-left: 20px;
}

@media (max-width: 768px) {

  .cart-container .cart-card .cart-name,
  .cart-container .cart-card .cart-price {
    width: 90%;
    margin-left: 0px;
    text-align: center;
    margin-top: 4px;
  }
}

.cart-container .cart-card .cart-price-orignal {
  width: 150px;
  margin-left: 20px;
  color: var(--black);
}

@media (max-width: 768px) {
  .cart-container .cart-card .cart-price-orignal {
    width: 90%;
    margin-left: 0px;
    text-align: center;
    margin-top: 4px;
  }
}

.delete-btn {
  background-color: transparent;
  border: hidden;
  outline: none;
  cursor: pointer;
  color: red;
}

.inc,
.dec {
  cursor: pointer;
}

@media (max-width: 768px) {
  .delete-btn {
    margin-top: 4px;
  }

  .inc,
  .dec {
    margin-top: 4px;
  }
}

/* cart summary */
.cart-summary {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  border: 1px solid #e4e4e4;
}

.cart-summary .cart-summary-heading {
  border-bottom: 1px solid var(--black);
}

.cart-summary .cart-summary-price {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.cashout-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #000;
}

/* cashout */
.success-msg {
  background-color: var(--black);
  color: #fff;
  padding: 7px;
  font-size: 14px;
  font-weight: 600;
}

.success-msg .home-link {
  color: #fff;
}

.cart-headline {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 30px;
}

.cart-headline h5 {
  font-weight: 400;
}

@media (max-width: 768px) {
  .cart-headline h5 {
    font-size: 14px;
    font-weight: 300;
    text-align: center;
  }
}